/*
  unicode ranges for different scripts taken from google's nunito font face definition
  https://fonts.google.com/specimen/Nunito#standard-styles
*/

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-cyrillic-400-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-400-normal.woff)
      format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-cyrillic-ext-400-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-400-normal.woff)
      format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-latin-400-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-400-normal.woff)
      format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-latin-ext-400-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-400-normal.woff)
      format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-vietnamese-400-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-400-normal.woff)
      format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-cyrillic-400-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-400-italic.woff)
      format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-cyrillic-ext-400-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-400-italic.woff)
      format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-latin-400-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-400-italic.woff)
      format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-latin-ext-400-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-400-italic.woff)
      format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-vietnamese-400-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-400-italic.woff)
      format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-cyrillic-600-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-600-normal.woff)
      format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-cyrillic-ext-600-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-600-normal.woff)
      format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-latin-600-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-600-normal.woff)
      format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-latin-ext-600-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-600-normal.woff)
      format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-vietnamese-600-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-600-normal.woff)
      format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-cyrillic-600-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-600-italic.woff)
      format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-cyrillic-ext-600-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-600-italic.woff)
      format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-latin-600-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-600-italic.woff)
      format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-latin-ext-600-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-600-italic.woff)
      format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-vietnamese-600-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-600-italic.woff)
      format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-cyrillic-700-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-700-normal.woff)
      format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-cyrillic-ext-700-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-700-normal.woff)
      format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-latin-700-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-700-normal.woff)
      format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-latin-ext-700-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-700-normal.woff)
      format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-vietnamese-700-normal.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-700-normal.woff)
      format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-cyrillic-700-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-700-italic.woff)
      format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-cyrillic-ext-700-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-700-italic.woff)
      format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-latin-700-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-700-italic.woff)
      format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-latin-ext-700-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-700-italic.woff)
      format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-vietnamese-700-italic.woff2)
      format("woff2"),
    url(//cdn.medallia.com/alchemy-fonts/2.0.0/nunito-all-700-italic.woff)
      format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
