/* stylelint-disable max-nesting-depth */

$light-grey: #dbdcdd;
$grey: #a5a8aa;
$dark-grey: #333;
$bg-color: #ffffff;
$name-color: #77777C !default;
$stars-color: #D6B73D !default;
$select-color: #333333 !default;
$accessible-feedback-bg-color: #333 !default;
$black: #000000;

.col-centered {
  float: none;
  margin: 0 auto;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

html, body, #root {
  height: 100%;
}

body {
  padding: 0 15px;
  font-family: Nunito, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.survey-loading {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.feedback {
  background-color: $bg-color;
  background-repeat: repeat;
  background-position: left top;
  background-attachment: fixed;

  font-size: 16px;

  .text {
    margin-bottom: 10px;
  }

  .wrapper {
    padding: 0;

    &.no-initial-feedback {
      border-bottom: none;
    }
  }

  .header {
    text-align: center;
    border-bottom: 1px solid $grey;

    img {
      max-width: 100%;
      height: auto;

      @media screen and (max-width: 767px) {
        max-height: 70px;
        width: auto;
      }
    }
  }

  .header {
    padding: 15px 0;
  }

  .intro-wrapper {
    padding: 15px 0 0 0;
  }

  .profile-wrapper {
    padding: 25px 0 0 0;
  }

  .rating-wrapper,
  .scalar-question-wrapper,
  .comments-wrapper,
  .rewards-wrapper,
  .multiple-select-grid-wrapper {
    padding: 40px 0 0 0;

    @media screen and (max-width: 767px) {
      padding: 20px 0 0 0;
    }
  }

  .intro,
  .star-rating-text,
  .thanks .text,
  .additional-question-wrapper .text {
    color: $dark-grey;
  }

  footer {
    font-size: 0.75em;
    border-top: 1px solid $grey;
    padding: 20px 0;

    .dynamic-text {
      margin-bottom: 15px;
    }

    &.no-initial-feedback {
      margin-top: 100px;
    }

    a {
      color: #000;
      text-decoration: underline;
      margin-right: 5px;
    }

    .powered-by {
      padding: 4px 10px 10px 10px;
      margin-bottom: 10px;

      a {
        text-decoration: none;
      }

      span {
        display: inline-block;
        margin-right: 4px;
      }

      img {
        display: inline-block;
        width: 70px;
        height: auto;
        margin-top: -5px;
        margin-left: 4px;
        -webkit-filter: grayscale(1);
      }
    }
  }

  .recovery-header-wrapper {
    display: flex;
    align-items: flex-start;
    margin: 15px 0;

    .profile-wrapper {
      width: 20%;
      flex: 0 1 20%;
      padding: 0;
      min-width: 64px;

      .profile .picture-wrapper {
        width: initial;
      }
    }

    .recovery-message-wrapper {
      width: 80%;
      margin-left: 20px;

      .recovery-message {
        padding: 5px 10px;
        margin: 0 0 5px;
        background: #f5f5f5;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        position: relative;

        &:before, &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 20px;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-right-color: #f5f5f5;
          border-left: 0;
          margin-top: -10px;
          margin-left: -10px;
          z-index: 2;
        }

        &:before {
          left: -1px;
          z-index: 1;
          border-right-color: #e6e6e6;
        }
      }

      .bio-wrapper {
        font-size: 14px;
        padding: 0 10px;

        .name,
        .location {
          display: inline;
        }

        .name:after {
          content: ', ';
        }
      }
    }
  }

  .rating-wrapper {
    text-align: center;
  }

  .stars {
    display: inline-block;
    padding: 10px 0 0 0;

    table {
      margin: 0 auto;
    }

    td.star-wrapper {
      width: 80px;

      @media screen and (max-width: 767px) {
        width: 56px;
      }
    }
  }

  .rewards-wrapper {
    .reward-options {
      display: flex;
      width: 100%;
    }

    .scalar-button-wrapper + .scalar-button-wrapper {
      border-left: 6px solid #ffffff;
    }

    .scalar-button-wrapper {
      width: 32.6667%;
      padding: 6px 6px 3px 6px;
      margin: 8px 0.5% 0 0.5%;
      border-radius: 0;
      border: 2px solid white;
      display: table-cell;
      word-wrap: break-word;
      white-space: normal;
      background-color: $accessible-feedback-bg-color;

      &:hover {
        background-color: lighten($accessible-feedback-bg-color, 10%);
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.active {
        background-color: $select-color;
      }

      label {
        display: block;
        font-size: 1.2em;
        padding-top: 3px;
      }
    }
  }

  .scalar-question-labels-wrapper {
    margin-top: -1.5rem;
    margin-bottom: .75rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    text-transform: uppercase;
    font-size: .875rem;
    column-gap: .5rem;
  }

  .scalar-question-label:nth-child(2) {
    text-align: right;
  }

  .multiple-select-grid-wrapper {
    .multiple-select-grid-options {
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
    }

    .reason {
      background-color: $accessible-feedback-bg-color;
      float: left;
      width: 32.6667%;
      padding: 10px 0;
      margin: 0 0.5% 4px 0.5%;
      border-radius: 0;
      border: none;
      font-size: 1.2em;

      &:hover {
        background-color: lighten($accessible-feedback-bg-color, 10%);
      }

      &:nth-of-type(3n+1) {
        margin-left: 0;
      }

      &:nth-of-type(3n) {
        margin-right: 0;
      }

      &.active {
        background-color: $select-color;
      }

      &.positive-reason {
        height: auto;
        padding: 10px 0;
      }
    }

    .reason-icon {
      display: block;
      margin-top: 10px;
      margin-bottom: 12px;
      font-size: 3.2em;
    }

    .reason-name {
      font-size: 0.9em;
    }

    @media screen and (max-width: 550px) {
      .reason {
        width: 49.5%;

        // resetting the default screen width settings
        &:nth-of-type(3n+1) {
          margin-left: 0.5%;
        }
        &:nth-of-type(3n) {
          margin-right: 0.5%;
        }

        // setting for this width
        &:nth-of-type(2n+1) {
          margin-left: 0;
        }
        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }

      .reason-icon {
        font-size: 3em;
        margin-top: 15px;
      }

      .reason-name {
        font-size: 0.8em;
      }
    }
  }

  .additional-question-wrapper {
    padding: 15px 0 30px 0;

    .text {
      padding-bottom: 5px;
    }
  }

  .thanks {
    padding: 15px 0;

    .social-actions {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 992px) {
        flex-direction: column;
      }
    }

    .twitter-share,
    .facebook-share,
    .twitter-follow,
    .facebook-follow {
      margin-bottom: 16px;
    }

    .share-suggestion-text {
      width: 100%;
      color: #6B7588;
      margin-top: 8px;
    }

    .tweet-text, .facebook-text {
      box-shadow: 0 1px 1px rgba(0,0,0,.05);
      background-color: #ffffff;
      color: $dark-grey;
      border: 1px solid #ddd;
      border-radius: 4px;
      padding: 14px;
      margin: 10px 0 12px;
      width: 100%;
    }

    .social-btn {
      color: #fff;
      border-radius: 0;
      font-size: 1.1em;
      text-align: left;
      padding: 0;
      height: 54px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      width: initial;
      margin: 0 10px 10px;

      @media screen and (max-width: 992px) {
        width: calc(100% - 20px);
      }

      > i {
        font-size: 1.4em;
        line-height: 1.7;
        border-right: 2px solid;
        left: 0;
        width: 56px;
        text-align: center;
      }

      > span {
        left: 5em;
        line-height: 3;
        padding: 0 1em 0 0.5em;
      }

      &.twitter {
        background-color: #55acee;
        i {
          border-right-color: darken(#55acee, 5%);
        }
      }

      &.facebook {
        background-color: #3b5999;
        i {
          border-right-color: darken(#3b5999, 5%);
        }
      }
    }
  }

  .errors {
    .error:first-of-type {
      padding-top: 20px;
    }

    .error:last-of-type {
      padding-bottom: 20px;
    }

    .error {
      text-align: center;
      padding: 5px 20px;
    }
  }

  .scalar-help-text {
    font-size: 14px;
    margin-bottom: 10px;
    width: 25%;
    color: $dark-grey;
  }

  .scalar-help-text.right {
    float: right;
    text-align: right;
  }

  .scalar-help-text.left {
    float: left;
    text-align: left;
  }

  .multiple-choice-radio {
    width: 1.1em;
    height: 1.1em;
    float: left;
  }

  .multiple-choice-options {
    margin-top: 10px;
  }

  .multiple-choice-label {
    display: block;
    margin-left: 5px;
    padding-bottom: 1em;
    font-weight: normal;
    color: $dark-grey;
    vertical-align: middle;
  }
}

.scalar-question-options {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 30px;

  &:hover {
    .star-rating-tile .empty .star-polygon {
      fill: lighten($stars-color, 20%);
    }
  }

  .star-rating-tile {
    .star-button {
      padding: 0;
    }

    &:hover ~ .star-rating-tile .empty .star-polygon {
      fill: #d0d2d3;
    }
  }
}

.tile-button-wrapper,
.star-rating-tile {
  position: relative;

  .tile-subtitle {
    position: absolute;
    white-space: nowrap;
    font-size: 14px;
    color: #000;
    text-align: center;
    text-transform: uppercase;
    width: auto;
  }

  &:first-child {
    .tile-subtitle {
      left: 0;
    }
  }

  &:last-child {
    .tile-subtitle {
      right: 0;
    }
  }
}

.question-options-wrapper {
  button {
    border: 0;
    background: none;
    font-size: .9em;
  }

  .tile-button-wrapper {
    background-color: #666;
    float: left;
    width: 32.6667%;
    margin: 0 .5% 4px;
    border-radius: 0;
    border: none;
    text-align: center;
    font-size: 1.2em;
    display: flex;
    position: relative;

    button {
      padding: 10px 5px;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      width: 100%;
      display: block;
      line-break: loose;
      hyphens: auto;

      &:hover {
        background-color: gray;
      }

      img {
        display: block;
        margin: 0 auto 12px;
        height: 80px;
        width: 80px;
        @media screen and (max-width: 550px) {
          height: 60px;
          width: 60px;
        }
      }
    }

    .btn.active {
      background: #333;
    }

    &:nth-of-type(3n+1) {
      margin-left: 0;
    }

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

.question-wrapper, .comment-wrapper {
  padding: 40px 0 0;
}

.comment-wrapper {
  textarea {
    width: 100%;
    padding: 15px;
    background-color: #ffffff;
    box-sizing: border-box;
    border: 2px solid #d4dce2;
    resize: none;
    transition: border 0.5s;
  }

  textarea:focus {
    outline: none;
    border: 2px solid $select-color;
    border-radius: 0;
  }
}

.submit {
  width: 100%;
  margin: 20px auto;
}

.submit-button, .custom-link {
  width: 100%;
  padding: 20px;
  background-color: $accessible-feedback-bg-color;
  border: none;
  border-radius: 0;
  color: #ffffff;
  text-align: center;

  &:hover {
    background-color: lighten($accessible-feedback-bg-color, 10%);
    color: #ffffff;
  }
}

.question-text, .thanks h1, .intro-title {
  font-size: 1.5em;
  margin-top: 0;
  margin-bottom: 0;
  line-height: inherit;
  text-align: center;
  color: $dark-grey;
  margin-bottom: 10px;

  @media screen and (max-width: 767px) {
    font-size: 1.1em;
  }
}

.nps-wrapper {
  .tile-button-wrapper {
    flex-grow: 1;
    position: relative;
    background: none;
    margin: 0 !important;

    .btn {
      width: 100%;
      margin: 10px 0 20px 0;
      height: 50px;
      background-color: white;
      cursor: pointer;
      color: $dark-grey;
      vertical-align: middle;
      border-style: solid;
      border-width: 1px 0 1px 1px;
      text-align: center;
      border-radius: 0;

      &:hover {
        color: #fff;
      }
    }

    .tile-subtitle {
      bottom: -5px;
    }

    &:last-child {
      .btn {
        border-width: 1px 1px 1px 1px;
      }
    }
  }

  .scalar-question-options {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
  }
}

.profile {
  width: 100%;
  text-align: center;

  .picture-wrapper {
    width: 30%;
    margin: 0 auto;

    .picture {
      img {
        width: 100%;
        height: auto;
        border-radius: 5px;
      }
    }
  }

  .bio-wrapper {
    width: 100%;
    margin: -10px auto 0 auto;
    padding: 10px 15px 10px 15px;
    background-color: #f5f5f5;

    .name {
      font-size: 1.8em;
      line-height: inherit;
      color: $name-color;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        font-size: 1.4em;
      }
    }

    .bio, .location {
      font-size: 1.2em;
      line-height: inherit;
      color: $dark-grey;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        font-size: 0.9em;
      }
    }

    .location {
      margin-top: 0;
    }

    .bio {
      margin-top: 10px;
    }
  }
}

.star-button {
  border: none;

  svg.star-svg {
    width: 70px;
    height: 70px;
    display: inline-block;

    @media screen and (max-width: 767px) {
      width: 50px;
      height: 50px;
    }
  }

  &.empty {
    .star-polygon {
      fill: #d0d2d3;
    }
  }

  &.filled {
    .star-polygon {
      fill: $stars-color;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.status-page {
  text-align: center;
  margin: 3em 0;

  .status-image {
    width: 100%;
    max-width: 288px;
  }

  .status-header {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin: 24px 0 12px;
  }

  .status-body {
    font-size: 16px;
    line-height: 22px;
  }
}
